import { render, staticRenderFns } from "./authenticated.vue?vue&type=template&id=a812811e"
import script from "./authenticated.vue?vue&type=script&lang=js"
export * from "./authenticated.vue?vue&type=script&lang=js"
import style0 from "./authenticated.vue?vue&type=style&index=0&id=a812811e&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/codebuild/output/src545922095/src/and-dine-hub/components/NavBar.vue').default,SideBar: require('/codebuild/output/src545922095/src/and-dine-hub/components/SideBar.vue').default})
